export enum ProductType {
  REGULAR = 0,
  GOLD = 1,
}

export interface ProductsFetchResponse {
  data: Product[];
}

export interface ProductFetchResponse {
  data: Product;
}

export interface Product {
  id: number;
  name: string;
  subtitle: string;
  slug: string;
  description: string;
  long_description: string;
  price: number;
  discount?: number;
  sale_price: number;
  stock: number;
  is_gold: boolean | ProductType;
  is_taxable: boolean;
  sku: string;
  width: number;
  height: number;
  length: number;
  weight: number;
  categories: Category[];
  images: string[];
  thumbnail: string;
  cover: string;
  videos: Media[];
  tags: Tag[];
  associations: ProductAssociations[];
  dosages: ProductDosage[];
  benefits: ProductMeta[];
  ingredients: ProductMeta;
  usage: ProductMeta;
  meta_title: LocalizedString;
  meta_description: LocalizedString;
}

interface LocalizedString {
  [key: string]: string; // Any string can be a key, and the value must be a string
}

export interface Media {
  id: number;
  location: string;
  product_id: number;
  created_at: string;
  updated_at: string;
}

export interface Category {
  id: number;
  name: string;
  slug: string;
  image: string;
  meta_title: LocalizedString;
  meta_description: LocalizedString;
  created_at: string;
  updated_at: string;
}

export interface Tag {
  id: number;
  name: string;
  slug: string;
  created_at: string;
  updated_at: string;
}

export interface ProductAssociations {
  id: number;
  type: ProductAssociationsType;
  reason: string;
  associated_product: Product;
}

export enum ProductAssociationsType {
  UP = 1,
  CROSS = 2,
  RELATED = 3,
}

export interface ProductDosage {
  id: number;
  product_id: number;
  name: string;
  value: string;
  created_at: string;
  updated_at: string;
}

export interface ProductMeta {
  id: number;
  product_id: number;
  name: string;
  key: string;
  value: string;
  created_at: string;
  updated_at: string;
}
