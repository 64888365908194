<template>
  <div class="product-card mb-4 hover:bg-primary-1000">
    <div class="product-discount-tag absolute top-3 rtl:left-0" v-if="product.sale_price">
      {{ calculateDiscount(product.price, product.sale_price) }}% {{ t('common.off') }}
    </div>

    <button v-if="isInWishlist" @click="removeFromWishlist(product.id)" class="absolute top-3 right-3">
      <Icon name="ph:heart-fill" size="28" class="text-primary-1000" />
    </button>

    <button v-else @click="handleAddtoWishlist(product.id)" class="absolute top-3 right-3">
      <Icon name="ph:heart" size="28" class="text-primary-1000" />
    </button>

    <NuxtLink :to="generateLink(`/product/${product.slug}`)" @click="handleTracking" class="w-full">
      <NuxtImg class="product-image h-44 md:h-60" :src="product.thumbnail" format="webp" :alt="product.name" />
    </NuxtLink>

    <div
      class="text-center py-1 px-2 border-t-[3px] h-full flex flex-col md:py-2 md:px-4"
      :class="{
        'bg-gold-100 border-gold-1000': product.is_gold == ProductType.GOLD,
        'bg-gradient-to-b from-neutral-50 to-neutral-100 via-slate-100': isDiamond,
        'bg-primary-100 border-primary-1000': !product.is_gold && !isDiamond,
      }"
    >
      <NuxtLink :to="generateLink(`/product/${product.slug}`)" @click="handleTracking">
        <h2 class="product-name font-bold" :class="isDiamond ? 'text-neutral-900' : 'text-primary-1000'">
          {{ product.name }}
        </h2>
      </NuxtLink>
      <p class="text-sm leading-4 mt-0.5 product-description font-medium text-primary-400 capitalize">
        {{ product.subtitle }}
      </p>

      <div class="pt-4 mt-auto">
        <div class="relative flex flex-col">
          <span
            class="product-price"
            :class="[
              isDiamond ? 'text-neutral-900' : 'text-primary-1000',
              product.sale_price
                ? 'text-xs line-through text-neutral-400 absolute left-1/2 -translate-x-1/2 bottom-5'
                : 'font-bold',
            ]"
            >{{ t('common.' + userCurrency) }} {{ formatPrice(product.price, userCurrency) }}</span
          >
          <span class="product-price font-bold text-primary-1000" v-if="product.sale_price"
            >{{ t('common.' + userCurrency) }} {{ formatPrice(product.sale_price, userCurrency) }}</span
          >
        </div>
      </div>
      <div class="flex gap-x-2 items-center mt-2 pb-2 md:pb-4">
        <div v-if="product.stock !== 0 && showQuantitySelector" class="w-1/2">
          <SharedQuantitySelector v-model="quantity" :dropdown-direction="'top'" :show-label="false" />
        </div>

        <button
          class="flex justify-center relative ml-auto text-primary-100 w-full py-2 rounded-md"
          :class="[
            isDiamond ? 'bg-neutral-900 text-neutral-100 hover:bg-neutral-600' : 'bg-primary-1000 text-primary-100',
            product.stock === 0
              ? 'justify-center cursor-not-allowed bg-primary-1000/50 hover:bg-primary-1000/50'
              : 'justify-around hover:bg-primary-800',
          ]"
          @click.stop="handleAddToCart"
          :disabled="isAddingToCart || product.stock === 0"
        >
          <span v-if="isAddingToCart">
            <Icon name="tabler:loader-2" size="18" class="animate-spin" />
          </span>
          <span v-else-if="product.stock === 0">{{ t('common.out_of_stock') }}</span>
          <span v-else>{{ t('common.add_to_cart') }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ProductType, type Product } from '~/interfaces/Product';
import { calculateDiscount } from '~/utils/discount';

const { t, locale } = useI18n();
const gtm = useGtm();
const config = useRuntimeConfig();

const props = defineProps({
  product: {
    type: Object as PropType<Product>,
    required: true,
  },
  showQuantitySelector: {
    type: Boolean,
    default: true,
  },
  isDiamond: {
    type: Boolean,
    default: false,
  },
});

const quantity = ref(1);
const isAddingToCart = ref(false);

const { addToCart: KlaviyAddToCart, viewProduct, addToWishlist: KlaviyoAddToWishlist } = useKlaviyo();

const settingsStore = useSettingsStore();
const cartStore = useCartStore();
const wishlistStore = useWishlistStore();
const authStore = useAuthStore();

const { wishlistItems } = storeToRefs(wishlistStore);
const { items } = storeToRefs(cartStore);
const { user } = storeToRefs(authStore);
const { userCurrency } = storeToRefs(settingsStore);

const router = useRouter();

const handleTracking = () => {
  gtm?.trackEvent({
    event: 'view_item',
    navigated_from: router.options.history.state.current,
    ecommerce: {
      currency: 'AED',
      value: formatPrice(props.product.price),
      items: [
        {
          item_id: props.product.id,
          item_sku: props.product.sku,
          item_name: props.product.meta_title['en'],
          item_category: props.product.categories?.[0]?.name,
          item_category2: props.product.categories?.[1]?.name,
          item_category3: props.product.categories?.[2]?.name,
          price: formatPrice(props.product.price),
          discount: formatPrice(props.product.sale_price),
          quantity: quantity.value,
        },
      ],
    },
  });

  viewProduct({
    LanguageCode: locale.value,
    ProductName: props.product.name,
    ProductID: props.product.id,
    SKU: props.product.sku,
    Categories: props.product.categories?.map((category) => category.name).join(', '),
    ImageURL: props.product.cover,
    URL: window.location.href,
    Brand: 'Biogena',
    Price: props.product.price,
    PriceCurrency: 'AED',
    CompareAtPrice: props.product.sale_price,
  });
};

const handleAddToCart = async () => {
  isAddingToCart.value = true;

  gtm?.trackEvent({
    event: 'add_to_cart',
    is_sticky: false,
    added_from: router.options.history.state.current,
    ecommerce: {
      currency: 'AED',
      value: formatPrice(props.product.price),
      items: [
        {
          item_id: props.product.id,
          item_sku: props.product.sku,
          item_name: props.product.meta_title['en'],
          item_category: props.product.categories?.[0]?.name,
          item_category2: props.product.categories?.[1]?.name,
          item_category3: props.product.categories?.[2]?.name,
          price: formatPrice(props.product.price),
          discount: formatPrice(props.product.sale_price),
          quantity: quantity.value,
        },
      ],
    },
  });

  KlaviyAddToCart({
    LanguageCode: locale.value,
    $value: props.product.price,
    AddedItemProductName: props.product.name,
    AddedItemProductID: props.product.id,
    AddedItemSKU: props.product.sku,
    AddedItemCategories: props.product.categories?.map((category) => category.name).join(', '),
    AddedItemImageURL: props.product.thumbnail,
    AddedItemURL: generateLink(`/product/${props.product.slug}`),
    AddedItemPrice: props.product.sale_price || props.product.price,
    AddedItemPriceCurrency: 'AED',
    AddedItemQuantity: quantity.value,
    ItemNames: items.value.map((item) => item.name),
    CheckoutURL: config.public.APP_URL + '/checkout',
    Items: items.value.map((item) => ({
      ProductID: item.id,
      SKU: item.sku,
      ProductName: item.name,
      Quantity: item.quantity,
      ItemPrice: item.price,
      PriceCurrency: 'AED',
      RowTotal: item.price * item.quantity,
      ProductURL: generateLink(`/product/${item.slug}`),
      ImageURL: item.thumbnail,
      ProductCategories: item.categories?.map((category) => category.name),
    })),
  });

  try {
    await cartStore.addToCart(props.product, quantity.value, locale.value);
  } catch (error) {
    console.error('Error adding to cart:', error);
  } finally {
    isAddingToCart.value = false;
  }
};

const handleAddtoWishlist = async (productId: number) => {
  gtm?.trackEvent({
    event: 'add_to_wishlist',
    ecommerce: {
      currency: 'AED',
      value: formatPrice(props.product.price),
      items: [
        {
          item_id: props.product.id,
          item_sku: props.product.sku,
          item_name: props.product.meta_title['en'],
          item_category: props.product.categories?.[0]?.name,
          item_category2: props.product.categories?.[1]?.name,
          item_category3: props.product.categories?.[2]?.name,
          price: formatPrice(props.product.price),
          discount: formatPrice(props.product.sale_price),
          quantity: 1,
        },
      ],
    },
  });

  KlaviyoAddToWishlist({
    LanguageCode: locale.value,
    $value: props.product.price,
    WishlistItemProductName: props.product.name,
    WishlistItemProductID: props.product.id,
    WishlistItemSKU: props.product.sku,
    WishlistItemCategories: props.product.categories?.map((category) => category.name).join(', '),
    WishlistItemImageURL: props.product.thumbnail,
    WishlistItemURL: generateLink(`/product/${props.product.slug}`),
    WishlistItemPrice: props.product.sale_price || props.product.price,
    WishlistItemPriceCurrency: 'AED',
    ItemNames: wishlistItems?.value?.map((item) => item.name) || [],
    Items:
      wishlistItems?.value?.map((item) => ({
        ProductID: item.id,
        SKU: item.sku,
        ProductName: item.name,
        ItemPrice: item.price,
        PriceCurrency: 'AED',
        ProductURL: generateLink(`/product/${item.slug}`),
        ImageURL: item.thumbnail,
        ProductCategories: item.categories?.map((category) => category.name),
      })) || [],
  });

  if (!user.value) {
    authStore.showLoginModal = true;
    return;
  }

  try {
    await useFetch('/api/wishlist/add-to-wishlist', {
      method: 'POST',
      body: {
        products: [productId],
      },
      headers: {
        'Accept-Language': locale.value.split('-')[0],
      },
    });

    // Update the wishlist store
    wishlistStore.addToWishlist(props.product);
  } catch (error) {
    console.error('Error adding to wishlist:', error);
  }
};

const removeFromWishlist = async (productId: number) => {
  gtm?.trackEvent({
    event: 'remove_from_wishlist',
    ecommerce: {
      currency: 'AED',
      value: formatPrice(props.product.price),
      items: [
        {
          item_id: props.product.id,
          item_sku: props.product.sku,
          item_name: props.product.meta_title['en'],
          item_category: props.product.categories?.[0]?.name,
          item_category2: props.product.categories?.[1]?.name,
          item_category3: props.product.categories?.[2]?.name,
          price: formatPrice(props.product.price),
          discount: formatPrice(props.product.sale_price),
          quantity: 1,
        },
      ],
    },
  });

  try {
    await useFetch('/api/wishlist/delete-from-wishlist', {
      method: 'DELETE',
      body: {
        product_id: productId,
      },
    });

    // Update the wishlist store
    wishlistStore.removeFromWishlist(props.product);
  } catch (error) {
    console.error('Error removing from wishlist:', error);
  }
};

const isInWishlist = computed(() => {
  return wishlistItems.value?.some((item) => item.id === props.product.id);
});
</script>

<style scoped>
.product-card {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;

  @apply h-[375px] md:h-[430px];
}

.product-discount-tag {
  display: inline-block;

  width: auto;

  background-color: #db643f;
  -webkit-border-radius: 3px 4px 4px 3px;
  -moz-border-radius: 3px 4px 4px 3px;
  border-radius: 3px 4px 4px 3px;

  border-left: 1px solid #db643f;

  margin-left: 12px;

  color: white;
  font-weight: bold;

  @apply text-sm h-5 px-2 py-0.5;
}

.product-image {
  width: 100%;
  max-width: 250px;
  margin: auto;
  object-fit: contain;
}
</style>
